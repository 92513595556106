import { CurrentUserOpts, SignOutOpts } from '@aws-amplify/auth/lib-esm/types';
import { Auth as AmplifyAuth } from 'aws-amplify';

export function parseJwtClaim (token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = window.atob(base64);

  return JSON.parse(jsonPayload);
}

export const Auth = {
  currentSession() {
    const ssoToken = sessionStorage.getItem('sso_token');
    if (ssoToken) {
      return Promise.resolve({
        getIdToken: () => ({
          getJwtToken: () => ssoToken,
        }),
      });
    }
    return AmplifyAuth.currentSession();
  },
  currentAuthenticatedUser: (opts?: CurrentUserOpts) => {
    const ssoToken = sessionStorage.getItem('sso_token');
    if (ssoToken) {
      const payload = parseJwtClaim(ssoToken);
      return Promise.resolve({
        username: payload.email,
        attributes: {
          email: payload.email,
        },
      });
    }
    return AmplifyAuth.currentAuthenticatedUser(opts);
  },
  signIn: AmplifyAuth.signIn.bind(AmplifyAuth),
  signOut: (opts?: SignOutOpts) => {
    sessionStorage.removeItem('sso_token');
    return AmplifyAuth.signOut(opts);
  },
  completeNewPassword: AmplifyAuth.completeNewPassword.bind(AmplifyAuth),
};
